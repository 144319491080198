/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { useIdentityContext, IdentityContextProvider } from 'react-netlify-identity-widget';
import IdentityModal from './netlify-identity-widget';
import { navigate } from 'gatsby';
import { globalHistory } from '@reach/router'
import LogRocket from 'logrocket';

import Header from "./header"
import "./layout.css"
import './netlify-identity-widget/styles.css';
import '@fortawesome/fontawesome-free/css/all.css';

LogRocket.init('7ryk0l/library-h90pr');

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const identity = useIdentityContext();
  const name = (identity && identity.user && identity.user.user_metadata && identity.user.user_metadata.full_name) || 'there';
  const isLoggedIn = identity && identity.isLoggedIn;
  const isLoginModalOpen = !isLoggedIn;
  const [dialog, setDialog] = React.useState(isLoginModalOpen);

  // LogRocket.identify(identity.user.id, {
  //   name: name,
  //   email: identity.user.email,
  //
  //   // Add your own custom user variables here, ie:
  //   roles: identity.user.app_metadata.roles
  // });

  console.log(globalHistory);

  const onCloseDialog = () => {
    setDialog(false);
    if (isLoggedIn && !globalHistory.location.pathname.includes('books')) {
      navigate('/books');
    }
  };

  if (isLoggedIn && !globalHistory.location.pathname.includes('books')) {
    navigate('/books');
  } else if (!isLoggedIn && globalHistory.location.pathname.includes('books')) {
    navigate('/');
  }

  return (
    <>
      <button className="login-btn" onClick={() => setDialog(true)}>
        {isLoggedIn ? `Hello ${name}` : "LOG IN"}
      </button>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0px 1.0875rem 1.45rem`,
          paddingTop: 0,
        }}
      >
        <main>{children}</main>
        <footer>
          © {new Date().getFullYear()}
        </footer>
      </div>
      <IdentityModal showDialog={dialog} onCloseDialog={onCloseDialog} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
